import * as businessMatchingService from "services/business-matching.v2.service";
import businessMatchingStore from "common/stores/business-matching-v2.store";
import { useQuery } from "react-query";
import { filter } from "lodash";
import applicationStore from "common/stores/application.store";

export const useBusinessMatchingRegistrationQuery = (
  business_matching_id,
  filterState
) => {
  const [setRegistrations, setFetchErrorModal] = businessMatchingStore((e) => [
    e.setRegistrations,
    e.setFetchErrorModal,
  ]);
  return useQuery({
    queryKey: [
      "getBusinessMatchingRegistration",
      business_matching_id,
      filterState,
    ],
    queryFn: () =>
      businessMatchingService.getBusinessMatchingRegistration(
        business_matching_id,
        filterState
      ),
    onSuccess: (registrations) => {
      setRegistrations(registrations);
    },
    onError: (error) => {
      setFetchErrorModal(true);
      console.log("userQuery error");
    },
  });
};
export const useBusinessMatchingInfo = () => {
  const { setCampaigns, setFetchErrorModal, setCampaignId } =
    businessMatchingStore();

  return useQuery({
    queryKey: "getBusinessMatching",
    queryFn: () => businessMatchingService.getBusinessMatching(),
    onSuccess: (campaigns) => {
      const currentCampaign = filter(campaigns, (el) => el.is_active === true);
      currentCampaign?.[0] && setCampaignId(currentCampaign[0].id);
      setCampaigns(campaigns);
    },
    onError: (error) => {
      setFetchErrorModal(true);
    },
  });
};

export const useRegistrationInfoQuery = (paylpoad) => {
  const { initialApplicationData } = applicationStore();
  const setModal = businessMatchingStore((e) => e.setModal);
  return useQuery({
    queryKey: ["useRegistrationInfoQuery", paylpoad],
    queryFn: () => businessMatchingService.getRegistrationInfo(paylpoad),
    onSuccess: (registration) => {
      if (!registration) {
        return setModal({ visible: true, type: "deleted" });
      }
      initialApplicationData(registration);
    },
    onError: (error) => {
      console.log("userQuery error");
    },
  });
};

export const useBusinessMatchingSelectProductNumberQuery = (payload) => {
  const { setProductNumberList, setFetchErrorModal } = businessMatchingStore();
  return useQuery({
    queryKey: ["useBusinessMatchingSelectProductNumberList", payload],
    queryFn: () =>
      businessMatchingService.getBusinessMatchingDatePending(payload),
    onSuccess: (registrations) => {
      setProductNumberList(registrations);
    },
    onError: (error) => {
      setFetchErrorModal(true);
      console.log("userQuery error");
    },
  });
};

export const useBusinessMatchingGetTimeSlot = (payload) => {
  const { setTimeSlots, setFetchErrorModal } = businessMatchingStore();
  return useQuery({
    queryKey: ["useBusinessMatchingGetTimeSlot", payload],
    queryFn: () => businessMatchingService.getTimeSlot(payload),
    enabled: !!payload.assignee_id || !!payload.campaign_id,
    onSuccess: (timeSlotList) => {
      let timeSlotMeeting = [];
      if (timeSlotList.length > 0) {
        timeSlotMeeting = timeSlotList.map((el) => {
          return {
            title: "เจรจาธุรกิจ",
            start: el.time_slot_available.from_datetime,
            end: el.time_slot_available.to_datetime,
            extendedProps: {
              data: el,
            },
          };
        });
      }
      setTimeSlots(timeSlotMeeting);
    },
    onError: (error) => {
      setFetchErrorModal(true);
      console.log("userQuery error");
    },
  });
};

export const useBusinessMatchingGetBuyerEmails = () => {
  const [emailChecked, setEmailChecked, setBuyerEmails, setFetchErrorModal] =
    businessMatchingStore((state) => [
      state.emailChecked,
      state.setEmailChecked,
      state.setBuyerEmails,
      state.setFetchErrorModal,
    ]);
  return useQuery({
    queryFn: () => businessMatchingService.getBuyerFromProductCategory(),
    onSuccess: (buyerEmailList) => {
      setEmailChecked(emailChecked ?? buyerEmailList[0].id);
      setBuyerEmails(buyerEmailList);
    },
    onError: (error) => {
      setFetchErrorModal(true);
      console.log("userQuery error");
    },
  });
};

export const useBusinessMatchingGetPurchase = (payload) => {
  return useQuery({
    queryKey: ["useBusinessMatchingGetPurchase", payload],
    queryFn: () => businessMatchingService.getBuyerFromProductCategory(payload),
    onError: (err) => console.log("error", err),
  });
};
